export function copyExecuted(targetButton, targetInput) {
  $(targetButton).on('click', () => {
    const $tempInput = $('<textarea>');
    $('body').append($tempInput);
    $tempInput.val($(targetInput).val()).trigger('select');
    document.execCommand('copy');
    $tempInput.remove();
    alert('Copied to clipboard!');
  });
}

export function copyExecutedButton(targetButton) {
  $(targetButton).on('click', function() {
    const url = $(this).data('url');

    navigator.clipboard.writeText(url).then(() => {
      alert('Copied to clipboard!');
    });
  });
}

export function copyExecutedOneTime(targetButton, targetInput) {
  $(targetButton).one('click', () => {
    const $tempInput = $('<textarea>');
    $('body').append($tempInput);
    $tempInput.val($(targetInput).val()).trigger('select');
    document.execCommand('copy');
    $tempInput.remove();
    alert('Copied to clipboard!');
  });
}

export function copyExecutedExternalLink() {
  const targetButtons = $('.copy-external-link');

  targetButtons.each(function () {
    const externalLinkId = $(this).data('id')
    const targetInput = `#external-link-${externalLinkId}`;
    copyExecuted(this, targetInput);
  });
}
