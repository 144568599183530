import Raicon from 'raicon';

import OverviewBioModalForm from './overviewBioModalForm';
import OverviewRefereeList from '../overviews/overviewRefereeList';
import OverviewFcActivities from './overviewFcActivities';
import { changeStatusTask } from '../tasks/triggerChangeStatus';
import IpreciateMailingForm from '../overviews/ipreciateMailingForm';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import { triggerCallbackCocoonLog, toggleFormCocoonCollapse } from '@/layouts/application/packages/cocoon';
import AppointmentForm from '../planners/appointmentForm';
import ProductForm from '../cases/productForm';
import CaseActivities from './caseActivities';
import TaskForm from '@/controllers/consultant/tasks/formTask';
import { fillDataForAddContact } from '../shared/fillDataForAddContact';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { add10Referees } from '../add10Referees';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import taskFormEvents from '../tasks/events';
import EkycForm from '../ekycs/ekycForm';
import { toggleElementByCheckboxEvent } from '../toggleElementByCheckboxEvent';
import InsurancePolicyForm from '../insurances/insurancePolicyForm';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import OverviewUploadDocumentForm from '@/layouts/application/utils/dropzone/overviewUploadDocumentForm';
import { handleToggleDreamPlanning } from '../handleToggleDreamPlanning';
import OverlayCanvas from '../../shared/overlayCanvas';
class ConsultantBiodataController {
  constructor() {
    this.ekycTable = '#ekyc-table';
    this.deleteContactWrapper = '#delete-contact-wrapper';
  }

  index() {
    this.bioSetup();
    this.refereeSetup();
    this.fcActivitySetup();
    this.caseActivitiesSetup();
    changeStatusTask(
      '.task-complete, .task-reverse',
      '#todo-container',
      '#completed-todo-tasks',
      '#open-task-todo-overdue',
      '#open-task-todo-today',
      'task-complete',
      'task-reverse',
    );
    changeStatusTask(
      '.task-tocall-complete, .task-tocall-reverse',
      '#tocall-container',
      '#completed-tocall-tasks',
      '#open-task-tocall-overdue',
      '#open-task-tocall-today',
      'task-tocall-complete',
      'task-tocall-reverse',
    );
    this.addTriggerOpenModalEvents();
    this.setupAppointmentForm();
    this.addTriggerAddProductForLogAppointmentForm();
    this.triggerClickNextWeekTask();
    changeParamenterByTab('overview', 'active_tab');
    this.setupProductForm();
    fillDataForAddContact();
    this.autoCalculateAgeByInput();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.allowEmptySelect2();
    this.setupIpreciateMailingForm();
    this.setupEkycForm();
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    this.handleAddNeedAnalysisForm();
    this.handleCloseEditEkycModal();
    this.getEkycsAndDeleteContactButtonData();
    handleToggleDreamPlanning(['#open-investment', '#insurance-policy-form']);
    this.insurancePolicyFormSetup();
    this.handleShowPopupResendActivationEmail();
    this.setupGiftingCalculatorForm();
    this.setupOverlayCanvas();
    this.initDropZone();
  }

  initDropZone() {
    new OverviewUploadDocumentForm().setup();
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  handleAddNeedAnalysisForm() {
    $('body').on('modal:afterSetContent', '#add-need-analysis, #back-button-insurance', () => {
      $('.add-need-analysis-button').addClass('disabled');

      $('.asset-tag').on('click', (e) => {
        const assetButton = $(e.target).closest('.asset-tag');
        const addNeedAnalysisUrl = $(assetButton).data('url');
        $('.add-need-analysis-button').attr('href', addNeedAnalysisUrl);
        this.unselectAllPolicyButton();
        this.selectPolicyButton(assetButton);
        $('.add-need-analysis-button').removeClass('disabled');
      });
      $('#add-asset-investment').on('click', (e) => {
        const addInvestmentUrl = $(e.target).data('url');
        $('.add-need-analysis-button').attr('href', addInvestmentUrl);
        this.unselectAllPolicyButton();
        $('.add-need-analysis-button').removeClass('disabled');
      });
      $('#add-policy').on('click', () => {
        $('.add-need-analysis-button').attr('href', '');
        this.unselectAllPolicyButton();
        $('.add-need-analysis-button').addClass('disabled');
      });
    });
  }
  unselectAllPolicyButton() {
    $('.asset-tag').css('background-color', '#FFFFFF');
    $('.asset-tag').css('box-shadow', 'none');
  }
  selectPolicyButton(button) {
    $(button).css('background-color', '#F9F9F9');
    $(button).css('box-shadow', '4px 4px 4px rgba(0, 0, 0, 0.1)');
  }

  setupEkycForm() {
    $('body').on('modal:afterSetContent', '#add-ekyc-button, #dup-ekyc-button, #create-ekyc-form', () => {
      new EkycForm();
    });
  }

  handleCloseEditEkycModal() {
    $('body').on('modal:afterSetContent', '#edit-ekyc-button', () => {
      $('#open-ekyc-edit-page').on('click', () => {
        $('#edit-ekyc-modal').find('.modal-close-btn').click();
      });
    });
  }

  getEkycsAndDeleteContactButtonData() {
    const clientId = $(this.ekycTable).data('client-id');
    $.ajax({
      url: Routes.client_ekycs_consultant_ekycs_path({ client_id: clientId }),
      type: 'GET',
      success: (data) => {
        $(this.ekycTable).html(data.ekyc_table);

        if ($(this.deleteContactWrapper).length != 0) {
          $(this.deleteContactWrapper).html(data.btn_delete_contact);
        }
      },
    });
  }

  handleEkycAssetForm() {
    const $checkbox = $('#investment-recurring-checkbox');
    const $recurringForm = $('.recurring-investment-form');

    toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }

  setupIpreciateMailingForm() {
    new IpreciateMailingForm().setup();
  }

  bioSetup() {
    new OverviewBioModalForm().setup();
  }

  caseActivitiesSetup() {
    new CaseActivities().setup();
  }

  insurancePolicyFormSetup() {
    new InsurancePolicyForm().setup();
  }

  setupAppointmentForm() {
    $('body').on(
      'modal:afterSetContent',
      '#new-appointment-button, #appointment-form, #send-confirmation-email-form',
      () => {
        new AppointmentForm().setup();
      },
    );
  }

  triggerClickNextWeekTask() {
    $('body').on('click', '.btn-next-week', (e) => {
      const $this = $(e.target);
      $this.hide();
      $this.parent().find('.spinner-border').show();
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task, #open-edit-task, #edit-task-form', () => {
      new TaskForm().setup();
    });

    $('body').on('cocoon:after-insert', () => new taskFormEvents().setup());
    $('body').on('ajax:success', () => new taskFormEvents().setup());

    $('body').on('modal:afterSetContent', '#log-call, #edit-log-call, #new-log-call', () => {
      triggerCallbackCocoonLog();
      toggleFormCocoonCollapse();
    });
  }

  addTriggerAddProductForLogAppointmentForm() {
    $('body').on('modal:afterSetContent', '#open-log-appointment, #log-appointment-modal', () => {
      triggerCallbackCocoonLog();
      toggleFormCocoonCollapse();
    });
  }

  setupProductForm() {
    $('body').on(
      'modal:afterSetContent',
      '#open-new-tasks, #edit-case-overview, #edit-case-form, #open-log-appointment, #log-appointment-modal, #open-new-case, #how-is-your-call-form, #new-case-form, #open-log-appointment, #new-log-appointment-form, #edit-case-link, #edit-case-form',
      () => {
        new ProductForm();

        $('body').on('cocoon:after-insert', () => {
          new ProductForm();
        });
      },
    );
  }

  refereeSetup() {
    new OverviewRefereeList().setup();
  }

  fcActivitySetup() {
    new OverviewFcActivities().setup();
  }

  autoCalculateAgeByInput() {
    $('body').on(
      'modal:afterSetContent',
      '#new-referee-to-contact-button, #new-contact-form, #clone-contact-form, #new-contact-form',
      () => {
        calculateAgeByInput('#dob', '.input-age');
      },
    );
  }

  allowEmptySelect2() {
    $('body').on('modal:afterSetContent', '#open-new-referee, #edit-referee-form, #referee-form', () => {
      $('#referrer-selector').select2({
        allowClear: true,
        placeholder: 'Choose a referrer',
      });
    });
  }

  handleShowPopupResendActivationEmail() {
    const modal = $('#resend-activation-email-modal');
    if (modal.data('show')) {
      modal.modal('show');
      modal.removeClass('d-none');
    }
  }
}

Raicon.register('consultant/biodata', ConsultantBiodataController);
