import DropZoneForm from '@/layouts/application/utils/dropzone/dropZoneForm';

export default class ImportExternalLinkForm extends DropZoneForm {
  constructor() {
    super({
      isLoading: true,
      isFailedRecordsLink: true,
      overrideInput: '.override-duplication',
      formSelector: '#import-external-links-form',
      submitBtnSelector: '#submit-import-external-links',
      fileParamName: 'external_link[external_links_file]',
      importResultFormSelector: '#import-external-links-result-form'
    })
  }

  setup = () => {
    $('body').on('modal:afterSetContent', '#import-external-links-form, #import-external-links-button', () => {
      this.setupDropZone();
      this.setupEventDropZone();
    });
  }
}
