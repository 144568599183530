$(document).on('turbolinks:load', () => {
  showPopup();
  togglePopupEvent();
});

const showPopup = () => {
  const fcPopup = '#fc-popup';
  $(fcPopup).modal('show');
  $(fcPopup).removeClass('d-none');
};

const togglePopupEvent = () => {
  const fcPopup = '#fc-popup';
  const checkboxPopup = $('#checkbox-popup');
  const popupId = $(fcPopup).data('id');

  $(checkboxPopup).on('change', () => {
    const visible = !$(checkboxPopup).is(':checked');

    $.ajax({
      url: Routes.update_popup_visible_consultant_banner_managers_path(),
      type: 'PUT',
      data: { popup_id: popupId, visible: visible }
    });
  });
}
