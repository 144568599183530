import Raicon from 'raicon';
import FilterForm from '../../consultant/clients/filterForm';

class MerlinPromptsController {
  index() {}

  show() {}

  new() {
    this.setupFilterForm();
  }

  create() {
    this.setupFilterForm();
  }

  edit() {
    this.setupFilterForm();
  }

  update() {
    this.setupFilterForm();
  }

  setupFilterForm() {
    new FilterForm().setup();
  }
}

Raicon.register('admin/merlinPrompts', MerlinPromptsController);
