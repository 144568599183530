import {
  emailFormatValidator,
  phoneNumberFormatValidator,
  otherPhoneNumberFormatValidator,
  presentValidator,
  setupValidatorBaseOnCountryCode,
} from '@/layouts/application/utils/validators';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition';
import Raicon from 'raicon';

class PayForwardController {
  constructor() {
    this.currentStep = 0;
    this.maxStep = 5;
    this.background = '.background-container';
    this.formSelector = '#pay-forward-form';
    this.getStarted = '#get-started-button';
    this.stepQueues = [
      '#welcome-step',
      '#vacation-form',
      '#dream-form',
      '#investment-form',
      '#complaint-form',
      '#career-form',
    ];
    this.backgroundQueues = [
      '',
      '#vacation-background-container',
      '#dream-background-container',
      '#investment-background-container',
      '#complaint-background-container',
      '#career-background-container',
    ];
    this.buttonAddRecommends = [
      '#add-recommendation-from-vacation',
      '#add-recommendation-from-dream',
      '#add-recommendation-from-investment',
      '#add-recommendation-from-complaint',
      '#add-recommendation-from-career',
    ];
    this.buttonAddFields = [
      '#add-vacation-fields',
      '#add-dream-fields',
      '#add-investment-fields',
      '#add-complaint-fields',
      '#add-career-fields',
    ];
    this.navigationSection = '#navigation-section';
    this.nextButton = '#next-button';
    this.backButton = '#back-button';
    this.submitButton = '#submit-button';
    this.formInformationStepsSelector = '#form-information-steps';
    this.openCongratulationModal = '#open-congratulation-modal';
    this.emailExistedError = '.email-existed';
    this.firstPageRequiredFields = [
      {
        selector: '#pif-name',
        validators: [presentValidator],
      },
      {
        selector: '#pif-email',
        validators: [presentValidator, emailFormatValidator],
      },
      {
        selector: '#pif-mobile',
        validators: [presentValidator],
      },
    ];
    this.inputSelectChangeValidator = [];
  }

  new() {
    this.handleNextButton('create');
    this.setupCocoon();
    this.setup();
    this.setupToolTip();
  }

  create() {
    this.handleNextButton('create');
    this.setupCocoon();
    this.setup();
  }

  show() {
    this.handleNextButton('show');
    this.setup();
  }

  thankYou() {
    $(this.openCongratulationModal).click();
  }

  setup() {
    this.displayFirstPageError();
    this.handleGetStarted();
    this.handleBackButton();
    this.checkValidLeadWithPhoneNumber();
    this.changeInputChangeValue();
    activeByCheckBox('#pay-forward-btn', '#checkbox-term');
  }

  setupCocoon() {
    this.buttonAddFields.forEach((button) => {
      for (let i = 0; i < 5; i++) {
        $(button).click();
      }
      $(button).on('mouseout', function () {
        $('[data-toggle="tooltip"]').tooltip();
      });
    });
  }

  setupToolTip() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on('click', function () {
      $(this).tooltip('hide');
    });
  }

  changeInputChangeValue() {
    this.changeInputAndSelectValue();

    $(document).on('cocoon:after-insert', () => {
      this.changeInputAndSelectValue();
    });
  }

  changeInputAndSelectValue() {
    $('input, select').on('change', (e) => {
      const $target = $(e.target);
      $target.attr('value', $target.val());
      const excludedIds = ['pif-name', 'pif-mobile', 'pif-country-code', 'pif-email'];

      if (!$target[0].id.includes('_name') && !excludedIds.includes($target[0].id)) {
        const outerDiv = $target.closest('.row');
        const mobileNumber = $target.is('input[type="tel"]')
          ? $target.val()
          : outerDiv.find('.pay-forward-phone-number input[type="tel"]').val();
        const countryCode = $target.is('select') ? $target.val() : outerDiv.find('select').val();

        if (mobileNumber !== '') {
          const mobileNumberValidator = $target.is('input[type="tel"]')
            ? $target[0]
            : outerDiv.find('.pay-forward-phone-number input[type="tel"]')[0];
          const countryCodeValidator = $target.is('select') ? $target[0].id : outerDiv.find('select')[0].id;

          var validator = countryCode === '+65' ? phoneNumberFormatValidator : otherPhoneNumberFormatValidator;
          var index = this.inputSelectChangeValidator.findIndex(
            (item) => item[0].selector.id === mobileNumberValidator.id,
          );

          if (index === -1) {
            this.inputSelectChangeValidator.push([
              {
                selector: mobileNumberValidator,
                validators: [validator],
              },
              {
                selector: `#${countryCodeValidator}`,
                validators: [],
              },
            ]);
          } else {
            this.inputSelectChangeValidator.splice(index, 1, [
              {
                selector: mobileNumberValidator,
                validators: [validator],
              },
              {
                selector: `#${countryCodeValidator}`,
                validators: [],
              },
            ]);
          }
        }
      }
    });
  }

  checkValidLeadWithPhoneNumber() {
    $('#pay-forward-form').on('submit', (e) => {
      e.preventDefault();
      const fcId = $('#pay-forward-form .main-content').attr('fc-id');
      const mobileNumberList = [];

      const mobileNumberInputs = [
        '.pay_forward_referee_career_phone_number input',
        '.pay_forward_referee_complaint_phone_number input',
        '.pay_forward_referee_dream_phone_number input',
        '.pay_forward_referee_investment_phone_number input',
        '.pay_forward_referee_vacation_phone_number input',
      ];

      $(mobileNumberInputs.join(',')).each(function () {
        const value = $(this).val().trim();
        if (value !== '') {
          mobileNumberList.push(value);
        }
      });

      if (mobileNumberList.length === 0) {
        $('#pay-forward-form').off('submit').submit();
        return;
      }

      $.get(
        Routes.check_valid_lead_with_phone_number_pif_path(fcId, { mobile_number_list: mobileNumberList.join(',') }),
      ).done((data) => {
        alert(data.message);
        $('#pay-forward-form').off('submit').submit();
      });
    });
  }

  displayFirstPageError() {
    const $formInformationSteps = $(this.formInformationStepsSelector);

    if ($formInformationSteps.length > 0) {
      const firstPageError = parseInt($formInformationSteps.data('first-page-error'));
      this.setStep(firstPageError || 0);
    }
  }

  setStep(nextStep) {
    this.currentStep = nextStep;
    this.navigateToNextStep();
    this.updateNavigationSectionDisplay();
  }

  updateNavigationSectionDisplay() {
    if (this.currentStep == 0 || this.currentStep > 5) {
      $(this.navigationSection).addClass('d-none');
    } else {
      $(this.navigationSection).removeClass('d-none');
      if (this.currentStep == 5) {
        $(this.nextButton).addClass('d-none');
        $(this.submitButton).removeClass('d-none');
      } else {
        $(this.nextButton).removeClass('d-none');
        $(this.submitButton).addClass('d-none');
      }
    }
  }

  navigateToNextStep() {
    this.stepQueues.forEach((step, index) => {
      const $indexStepElement = $(step);

      if (index == this.currentStep) {
        $indexStepElement.removeClass('d-none');
      } else {
        $indexStepElement.addClass('d-none');
      }
    });
  }

  handleGetStarted() {
    $(this.getStarted).on('click', () => {
      this.nextStep();
    });
  }

  handleNextButton(type) {
    $(this.nextButton).on('click', () => {
      if (type == 'create') {
        this.nextStep();
      } else {
        this.nextStepForShow();
      }
    });
  }

  handleBackButton() {
    $(this.backButton).on('click', () => {
      this.backStep();
    });
  }

  nextStepForShow() {
    $(this.stepQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === this.maxStep) {
      $(this.nextButton).addClass('d-none');
      $(this.submitButton).removeClass('d-none');
    }
  }

  nextStep() {
    if (this.currentStep == 1) {
      const currentStepValidationPassed = this.validateCurrentStep();
      const nextStepValidationPassed = this.validateNextStep();

      if (!nextStepValidationPassed) return;
      if (!currentStepValidationPassed) return;
    }

    const nextStepValidationPassed = this.validateNextStep();

    if (!nextStepValidationPassed) return;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    $(this.backgroundQueues[this.currentStep]).addClass('d-none');
    this.currentStep += 1;
    $(this.navigationSection).removeClass('d-none');
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    $(this.backgroundQueues[this.currentStep]).removeClass('d-none');
    if (this.currentStep === this.maxStep) {
      $(this.nextButton).addClass('d-none');
      $(this.submitButton).removeClass('d-none');
    }
    $('html, body').animate({ scrollTop: $('.post-fna-container').position().top }, 'slow');
  }

  backStep() {
    const MIN_STEP = 0;

    $(this.stepQueues[this.currentStep]).addClass('d-none');
    $(this.backgroundQueues[this.currentStep]).addClass('d-none');
    this.currentStep -= 1;
    if (this.currentStep === MIN_STEP) {
      $(this.navigationSection).addClass('d-none');
    }
    $(this.stepQueues[this.currentStep]).removeClass('d-none');
    $(this.backgroundQueues[this.currentStep]).removeClass('d-none');
    $(this.nextButton).removeClass('d-none');
    $(this.submitButton).addClass('d-none');

    $(this.background).css('background-image', `url('${this.backgroundQueues[this.currentStep]}')`);
    $('html, body').animate({ scrollTop: $('.post-fna-container').position().top }, 'slow');
  }

  validateCurrentStep() {
    this.hideEmailError();
    let currentStepValidationPassed = true;
    let phoneNumberValidate = this.firstPageRequiredFields.find(
      (requiredField) => requiredField.selector === '#pif-mobile',
    );
    setupValidatorBaseOnCountryCode(phoneNumberValidate, '#pif-country-code');

    this.firstPageRequiredFields.forEach((fieldInfor) => {
      const fieldInput = $(fieldInfor.selector);
      let isValidField = true;

      fieldInfor.validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());

        currentStepValidationPassed = this.updateFieldValidationState(
          fieldInput,
          isValidField,
          currentStepValidationPassed,
        );
      });
    });

    return currentStepValidationPassed;
  }

  validateNextStep() {
    this.hideEmailError();
    let currentStepValidationPassed = true;

    this.inputSelectChangeValidator.forEach((item) => {
      setupValidatorBaseOnCountryCode(item[0].selector, item[1].selector);

      const fieldInput = $(item[0].selector);
      let isValidField = true;

      item[0].validators.forEach((validatorMethod) => {
        isValidField = validatorMethod.bind(this)(fieldInput.val());

        currentStepValidationPassed = this.updateFieldValidationState(
          fieldInput,
          isValidField,
          currentStepValidationPassed,
        );
      });
    });

    return currentStepValidationPassed;
  }

  updateFieldValidationState(fieldInput, isValidField, currentStepValidationPassed) {
    if (!isValidField) {
      currentStepValidationPassed = false;
      fieldInput.addClass('is-invalid');
      fieldInput.removeClass('is-valid');
      fieldInput.parent().parent().find('.invalid-feedback').show();
    } else {
      fieldInput.addClass('is-valid');
      fieldInput.removeClass('is-invalid');
      fieldInput.parent().parent().find('.invalid-feedback').hide();
    }
    return currentStepValidationPassed;
  }

  hideEmailError() {
    $(this.emailExistedError).hide();
  }
}

Raicon.register('payForwards', PayForwardController);
