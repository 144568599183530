import Raicon from 'raicon';
import ImportExternalLinkForm from '@/layouts/application/utils/dropzone/importExternalLinksForm';

class AdminExternalLinksController {
  constructor() {
    this.submitButton = '#submit-import-fc-brand';
  }

  index() {
    this.initDropZone();
  }

  initDropZone() {
    new ImportExternalLinkForm().setup();
  }

}

Raicon.register('admin/externalLinks', AdminExternalLinksController);
